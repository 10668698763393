import React from "react";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import GridItem from "components/Grid/GridItem.jsx";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { useState } from "react";
import { optionalFn } from "../../core/helpers";
import { CameraView } from "../../components/UploaderInputs/CameraView";
import { Button } from "@material-ui/core";
import { AuthFetch } from "../../utils/AuthFetch";
export function VisitorsForm({ onSubmission }) {
  const [form, setForm] = useState({
    fullname: "",
    address: "",
    plate: "",
    description: ""
  });
  const [myPhoto, setMyPhoto] = useState(null);
  const _willSubmit = async inputs => {
    if (myPhoto) {
      inputs.photo = myPhoto;
    }
    inputs.type = "Visitantes";
    inputs.limitedAccess = new Date().toISOString().substring(0, 10);
    __submit(inputs);
    return inputs;
  };

  const _afterSubmit = inputs => {
    optionalFn(onSubmission)(inputs);
  };
  const __submit = async inputs => {
    _storeCars(inputs);
    const fetch = new AuthFetch("/api/persons/");
    let response = (await fetch.post(inputs)).data;
    _afterSubmit(response);
  };
  const _storeCars = inputs => {
    const fetch = new AuthFetch("api/cars");
    return fetch.post(inputs);
  };
  return (
    <PanelContainer
      title="Registro de visitantes"
      subtitle="Llama por teléfono antes de registrar al usuario."
    >
      <GridItem xs={12} sm={12} md={12}>
        <CameraView
          autoStart={true}
          onPhotoTaken={image => {
            setMyPhoto(image);
          }}
          photo={myPhoto}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          upperCase
          title="Nombre completo"
          name="fullname"
          value={form.fullname}
          onBlur={ev => {
            setForm({ ...form, fullname: ev.target.value });
            return ev.target.value;
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          upperCase
          title="Número de afiliación"
          name="plate"
          value={form.plate}
          onBlur={ev => {
            setForm({ ...form, plate: ev.target.value });
            return ev.target.value;
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          upperCase
          title="Descripción"
          name="description"
          value={form.description}
          onBlur={ev => {
            setForm({ ...form, description: ev.target.value });
            return ev.target.value;
          }}
        />
      </GridItem>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={"submit"}
        onClick={() => _willSubmit(form)}
      >
        Guardar
      </Button>
    </PanelContainer>
  );
}
