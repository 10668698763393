import React, { Component } from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { insteadIMG } from "../../utils/Utils";

export class UserCard extends Component {
  /**
   * Imprime tag p para elementos varios.
   * @param {*} others
   */
  printOthers(others) {
    const items = [];
    for (let key in others) {
      const item = others[key];
      const render = (
        <p key={key}>
          <span>{key}:</span> {item}
        </p>
      );
      items.push(render);
    }
    return items;
  }
  render() {
    const {
      pic,
      type,
      fullName,
      okIcon,
      noIcon,
      onOk,
      onCancel,
      others,
      style,
      children
    } = this.props;
    return (
      <Card profile style={style}>
        <CardBody profile>
          <h4 className={"cardTitle"}>{fullName}</h4>
          <div
            style={{
              overflow: "hidden",
              width: "100%",
              height: "16rem"
            }}
          >
            <img
              src={insteadIMG(pic)}
              alt="..."
              style={{
                width: "auto",
                height: "100%",
                display: "inline",
                textAlign: "center",
                lineHeight: "24px"
              }}
            />
          </div>

          <h6 className={"cardCategory"}>
            <strong>Tipo:</strong>
            {type}
          </h6>
          {this.printOthers(others)}
          {okIcon ? (
            <Button color="primary" round onClick={onOk}>
              {okIcon}
            </Button>
          ) : (
            ""
          )}
          {noIcon ? (
            <Button color="danger" round onClick={onCancel}>
              {noIcon}
            </Button>
          ) : (
            ""
          )}
          <div
            style={{
              margin: "0 auto",
              display: "block",
              width: "fit-content"
            }}
          >
            {children}
          </div>
        </CardBody>
      </Card>
    );
  }
}
