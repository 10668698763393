import React, { useState } from "react";
import { AsyncDataTable } from "../../components/Table/AsycnDataTable";
import { DoorSelector } from "./DoorSelector";
export function AsyncHistory() {
  const [door, setEvent] = useState(null);
  return (
    <AsyncDataTable
      key={door}
      path="api/access"
      args={door ? { door } : {}}
      title="Historial de Accesos."
      subtitle="Datos Generales Accesos."
      hideButton
      prev={
        <>
          <DoorSelector
            title="Filtrar por evento"
            onChange={ev => {
              setEvent(ev);
            }}
          />
        </>
      }
      titles={{
        fullname: "Nombre",
        inside: "Estatus",
        door: "Evento",
        updated_at: "Fecha"
      }}
      formatContent={item => {
        return [
          item.fullname,
          item.inside ? "Entrada" : "Salida",
          item.door,
          new Date(item.updated_at.date).toLocaleDateString("es-MX", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit"
          })
        ];
      }}
    />
  );
}
