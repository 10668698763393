import React, { Component } from "react";

import { PanelContainer } from "../../components/Panel/PanelContainer";
import { AuthFetch } from "../../utils/AuthFetch";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { UserCard } from "../Users/UserCard";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import swal from "sweetalert";
import { Helpers, playAudio } from "../../core/helpers";
import { SearchUsers } from "../Users/SearchUsers";
import GridItem from "components/Grid/GridItem.jsx";
import { DoorSelector } from "./DoorSelector";
import { Persons } from "../../utils/Controller/Persons";
import { QrTicket } from "../../components/QR/QrTicket";
import validAccess from "assets/sound/validAccess.mp3";
import accessDenied from "assets/sound/accessDenied.mp3";
import helpSound from "assets/sound/help.mp3";
import wait from "assets/sound/wait.mp3";
import { VisitorsForm } from "./VisitorsForm";
import { ModalStreet } from "./ModalStreet";
import { ConditionalWall } from "../../components/FilterWall/ConditionalWall";
import { QrScan } from "../../components/QR/QrScan";

/**
 * @description Imprime el control de accesos.
 */

/* eslint eqeqeq: 0*/
export class AccessContainer extends Component {
  seconds = 0;
  type = 1;
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      openPerson: false,
      openRegister: false,
      localRegister: false,
      timeLeft: 0,
      door: false,
      ticket: null,
      openStreetSelector: false,
      label: ""
    };
    this.searchData = [];
    this.personData = [];
  }

  handleAdd = () => {
    this.logger.killItWithFire();
    this.setState({ openRegister: true });
  };
  /**
   * Search permite buscar en la api.
   * @param String search
   * @return Promise
   */
  willSearch = value => {
    const helpers = new Helpers();
    value = value.toLowerCase();
    const qrCode = helpers.isJsonString(value);
    if (qrCode === false || qrCode === null) {
      return true;
    }
    this.loadPersonByHash(qrCode.h);
    return "";
  };
  async loadPersonByID(id) {
    const person = await new Persons(id).get();
    this.loadPerson(person);
  }
  async loadPersonByHash(hash) {
    const person = await new Persons().by({ hash });
    this.loadPerson(person[0]);
  }
  async loadPerson(personData) {
    let type = this.type;
    this.personData = personData || {};
    if (typeof personData === "undefined") {
      playAudio(accessDenied);
      swal({
        title: "Advertencia",
        text: `QR invalido`,
        timer: 3000,
        icon: "warning"
      });
      return false;
    }
    let { hasAccess, limitedAccess, fullname, id } = personData;

    const diffDate = new Date(limitedAccess) - new Date();

    //En caso de usuario caduco retira permisos de acceso
    this._setAccess(id, diffDate < 0 && limitedAccess != null, () => {
      hasAccess = 0;
    });
    if (this.type == 2) {
      let response = await swal({
        title: "Acceso Peatonal",
        text: `¿${fullname} esta entrando o saliendo?`,
        icon: "warning",
        showCancelButton: false,
        type: "input",
        buttons: { cancel: "Salir", confirm: "Entrar" }
      });
      type = response ? 1 : 0;
    }
    if (hasAccess === 0 && type === 1) {
      let response = await swal({
        title: "Advertencia",
        text: `La persona ${fullname} no tiene permiso de acceso.`,
        showCancelButton: false,
        icon: "warning"
      });
      playAudio(accessDenied);
      return false;
    }
    this._setAccess(id, hasAccess === 0 && type === 0 && limitedAccess != null);
    this._startTimer(type);
  }
  _startTimer(type) {
    this.setState({ openPerson: true, ticket: null });
    playAudio(wait);
    this.onAccessTimeout(this.seconds || 1)
      .then(() => {
        this.grantAccess(this.personData.id, type);
      })
      .catch(opps => {
        console.log(opps);
      });
  }
  /**
   *
   * @param integer id
   * @param boolean submit
   * @param function callback
   */
  _setAccess(id, submit, callback = {}) {
    if (submit) {
      new Persons(id).setAccess(false, { hash: true });
    }
  }
  /**
   * onAccessTimeout dispara un evento cuando el contador llega a 0.
   * @param Integer time
   */
  onAccessTimeout(time = 1) {
    this.setState({ timeLeft: time });
    return new Promise((load, fail) => {
      const interval = setInterval(() => {
        time -= 1;
        this.setState({ timeLeft: time });
        if (this.cancelInterval) {
          fail();
          this.cancelInterval = false;
          clearInterval(interval);
        }
        if (time === 0) {
          load();
          clearInterval(interval);
        }
      }, 1000);
    });
  }
  /**
   * Request para permiso.
   * @param int personID
   */
  async grantAccess(personID, type) {
    playAudio(validAccess);
    const fetch = new AuthFetch(`api/access/${personID}`);
    const access = await fetch.post({ inside: type, door: this.state.door });
    let localRegister = this.state.localRegister;
    if (access.data.personID == personID) {
      this.openDoor();
      this.setState({ openPerson: false, localRegister: false });
    }
    if (localRegister) {
      new Persons(personID).setAccess(false);
    }
  }
  closePersonModal = () => {
    this.cancelInterval = true;
    this.setState({ openPerson: false });
  };
  async openDoor() {
    let door = this.state.door;
    const fetch = new AuthFetch(`api/doors/${door}/run`);
    let response = (await fetch.get()).data;
    if (response == false) {
      playAudio(helpSound);
      swal("Advertencia", `No se pudo abrir la puerta`, "error");
    }
  }
  componentDidMount() {
    this._logger();
  }

  _logger() {
    const helpers = new Helpers();
    this.logger = helpers.keylogger(searchChar => {
      if (document.activeElement.id !== "searchUser") {
        const dom = document.querySelector("#searchUser");
        if (dom) dom.focus();
      }
    });
  }

  componentWillUnmount() {
    this.logger.killItWithFire();
  }
  render() {
    let {
      openPerson,
      timeLeft,
      openRegister,
      search,
      door,
      ticket,
      openStreetSelector,
      label
    } = this.state;
    const { fullname, type, id, photo } = this.personData;
    return (
      <PanelContainer
        title={`Accesos para ${label}`}
        subtitle="Control de accesos"
      >
        <ConditionalWall conditional={door == false}>
          <GridItem xs={12}>
            <DoorSelector
              title="Seleccione la evento"
              value={door}
              onChange={(door, data) => {
                this.setState({ door, label: data[0].alias });
              }}
              getDoor={door => {
                this.seconds = door[0].seconds;
                this.type = door[0].type;
              }}
            />
          </GridItem>
        </ConditionalWall>
        <ConditionalWall conditional={door}>
          <GridItem md={4} xs={12}>
            <QrScan onScan={this.willSearch} />
          </GridItem>
          <GridItem md={8} xs={12}>
            <SearchUsers
              value={search}
              willSearch={this.willSearch}
              onInteraction={this.loadPersonByID.bind(this)}
              onAddClick={() => {
                this.handleAdd();
              }}
            />
          </GridItem>
          <CustomModal
            open={openPerson}
            onClose={this.closePersonModal}
            styles={{ height: "600px" }}
          >
            <UserCard
              pic={photo}
              fullName={fullname}
              type={type}
              okIcon={<Add />}
              onOk={() => {
                this.cancelInterval = true;
                this.grantAccess(id, this.type);
              }}
              onCancel={this.closePersonModal}
              noIcon={<Close />}
              style={{
                minWidth: "20rem",
                minHeight: "50vh",
                paddingTop: "1rem"
              }}
            >
              {`${timeLeft} segundos restantes`}
            </UserCard>
          </CustomModal>
          <CustomModal
            open={openRegister}
            onClose={() => {
              this.setState({ openRegister: false });
            }}
          >
            <VisitorsForm
              onSubmission={response => {
                this.setState({
                  openRegister: false,
                  ticket: response,
                  localRegister: true
                });
              }}
            />
          </CustomModal>
          {ticket ? (
            <QrTicket
              hash={JSON.stringify({ h: ticket.hash })}
              name={ticket.fullname}
              details={[
                ticket.plate,
                "Conserve su ticktet, sera requerido al salir de las instalaciones."
              ]}
              onPrint={() => {
                this.loadPersonByID(ticket.id);
              }}
              date={new Date().toLocaleDateString("es-MX", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit"
              })}
            />
          ) : (
            ""
          )}
        </ConditionalWall>
      </PanelContainer>
    );
  }
}
