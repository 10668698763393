/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import { DashboardRoutes } from "routes/dashboard.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/favicon.png";
import { Login } from "../../views/Login/Login";
import { LoginManager } from "../../utils/LoginManager";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      logged: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  handleRoutes = () => {
    const dashboardRoutes = new DashboardRoutes().get();
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect && this.props.location.pathname == prop.path) {
            return <Redirect from={prop.path} to={prop.to} key={key} />;
          }
          if (!prop.component) {
            return null;
          }
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}
      </Switch>
    );
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  async setLoginStatus() {
    const loginManager = new LoginManager();
    if (loginManager.isLogged()) {
      this.setState({ logged: true });
    }
    await loginManager.onLoginExpire();
    this.setState({ logged: false });
  }
  componentDidMount() {
    this.setLoginStatus();
    if (navigator.platform.indexOf("Win") > -1) {
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        {!this.state.logged ? (
          <main>
            <Login onLogin={this.setLoginStatus.bind(this)} />
          </main>
        ) : (
          <main>
            <Sidebar
              routes={new DashboardRoutes().get()}
              logoText={"Acceso Fácil"}
              logo={logo}
              image={image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="black"
              {...rest}
            />
            <div className={classes.mainPanel} ref="mainPanel">
              <Header
                routes={new DashboardRoutes().get()}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content + " appContainer"}>
                  <div className={classes.container}>{this.handleRoutes()}</div>
                </div>
              ) : (
                <div className={classes.map}>{this.handleRoutes()}</div>
              )}
              {this.getRoute() ? <Footer /> : null}
            </div>
          </main>
        )}
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);
