// @material-ui/icons
// core components/views
import { UsersView } from "../views/Users/UsersView";
import { LoginManager } from "../utils/LoginManager";
import { AccessContainer } from "../views/Access/AccessContainer";
import { DoorsContainer } from "../views/Access/DoorsContainer";
import { ProfileContainer } from "./../views/Users/ProfileContainer";
import { AsyncHistory } from "./../views/Access/AsyncHistory";

export class DashboardRoutes {
  get = () => {
    const login = new LoginManager();
    let profile = login.getToken();

    if (!profile) {
      return [];
    }

    profile = profile.permissions;
    let profileRender = this.routes.filter(item => {
      let accessCounter = false;
      if (typeof item.permissions === "undefined") {
        return true;
      }

      for (let index in item.permissions) {
        accessCounter = login.hasPermission(item.permissions[index]);
      }
      return accessCounter;
    });

    return profileRender;
  };

  routes = [
    {
      redirect: true,
      path: "/",
      to: "/accesos",
      navbarName: "Redirect"
    },

    {
      parent: true,
      sidebarName: "Usuarios",
      navbarName: "Usuarios",
      icon: "users",
      permissions: ["Usuarios"]
    },
    {
      path: "/personas/:types?",
      sidebarName: "Personas",
      childOf: "Usuarios",
      navbarName: "Personas",
      icon: "users",
      permissions: ["Usuarios", "Accesos"],
      component: UsersView
    },
    {
      path: "/personas/empleado",
      sidebarName: "Empleados",
      navbarName: "Empleados",
      childOf: "Usuarios",
      icon: "user-tie",
      permissions: ["Usuarios", "Accesos"]
    },
    {
      path: "/accesos/:door?",
      sidebarName: "Accesos",
      navbarName: "Accesos",
      icon: "door-open",
      permissions: ["Accesos"],
      component: AccessContainer
    },
    {
      path: "/historial/accesos",
      sidebarName: "Registro",
      navbarName: "Registro",
      icon: "clock",
      permissions: ["Accesos"],
      component: AsyncHistory
    },
    {
      path: "/perfiles",
      sidebarName: "Perfiles",
      navbarName: "Perfiles",
      icon: "address-book",
      permissions: ["Usuarios"],
      component: ProfileContainer
    },
    {
      path: "/historial/accesos",
      childOf: "Seguridad",
      sidebarName: "Registro",
      navbarName: "Registro",
      icon: "clock",
      permissions: ["Accesos"],
      component: AsyncHistory
    },
    {
      path: "/eventos",
      sidebarName: "Eventos",
      navbarName: "Eventos",
      icon: "calendar-week",
      permissions: ["Usuarios"],
      component: DoorsContainer
    }
  ];
}
