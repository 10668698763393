import React, { Component } from "react";
import { DataTable } from "../../components/Table/DataTable";
import { AuthFetch } from "../../utils/AuthFetch";
import { DoorRegister } from "./DoorRegister";
import { FontIcon } from "../../components/Icons/FontIcon";
import IconButton from "@material-ui/core/IconButton";
import swal from "sweetalert";

/* eslint eqeqeq: 0*/
export class DoorsContainer extends Component {
  state = {
    doors: [],
    modalOpen: false,
    doorID: null
  };
  componentDidMount() {
    this.loadDoors();
  }
  async testDoor(id) {
    const fetch = new AuthFetch(`api/doors/${id}/run`);
    let content = (await fetch.get()).data;
    if (content == true) {
      swal("Excelente", "Comando Ejecutado", "success");
    }
  }
  async deleteDoor(id) {
    const fetch = new AuthFetch(`api/doors/${id}`);
    await fetch.delete();
    this.loadDoors();
  }
  async loadDoors() {
    const fetch = new AuthFetch("api/doors");
    let doors = (await fetch.get()).data;
    doors = doors.map(item => {
      return [
        item.alias,
        item.seconds,
        types[item.type],
        <React.Fragment>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              this.setState({ modalOpen: true, doorID: item.id });
            }}
          >
            <FontIcon iconName="fa-edit" style={{ fontSize: "13px" }} />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              this.testDoor(item.id);
            }}
          >
            <FontIcon iconName="fa-play" style={{ fontSize: "13px" }} />
          </IconButton>
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              this.deleteDoor(item.id);
            }}
          >
            <FontIcon iconName="fa-trash" style={{ fontSize: "13px" }} />
          </IconButton>
        </React.Fragment>
      ];
    });
    this.setState({ doors, modalOpen: false });
  }
  render = () => {
    const { doors, doorID, modalOpen } = this.state;
    return (
      <DataTable
        title="Eventos"
        subtitle="Lista de eventos"
        titles={["Evento", "Espera en pantalla", "Tipo", "Acciones"]}
        content={doors}
        modalOpen={modalOpen}
        onClose={() => {
          this.setState({ modalOpen: false, doorID: null });
        }}
        inModal={
          <DoorRegister
            id={doorID}
            onSubmit={() => {
              this.loadDoors();
            }}
          />
        }
      />
    );
  };
}
export const types = ["Salida", "Entrada", "Peatonal"];
