import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import { ActionInput } from "../../components/CustomInput/ActionInput";
import Search from "@material-ui/icons/Search";
import { AuthFetch } from "../../utils/AuthFetch";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { UserCard } from "../Users/UserCard";
import Add from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.jsx";
import { Badge } from "../../components/Badges/Badges";
import { optionalFn } from "../../core/helpers";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { FontIcon } from "../../components/Icons/FontIcon";
import { FaButton } from "../../components/CustomButtons/FaButton";
import "./scss/missingCard.scss";

export class SearchUsers extends Component {
  state = {
    search: "",
    openSearch: false
  };
  searchData = [];
  handleSearch = value => {
    const { willSearch } = this.props;
    if (typeof willSearch === "function") {
      let itWill = willSearch(value);
      if (!itWill) return "";
    }
    this.search(value);
    return "";
  };
  async search(search) {
    if (search === "") {
      return false;
    }
    const fetch = new AuthFetch("api/persons");
    let searchData = await fetch.get({ search });
    this.searchData = searchData.data;
    this.setState({ openSearch: true, search });
  }

  printSearch(searchData) {
    const name = this.state.search.toUpperCase();
    if (searchData.length === 0) {
      return (
        <div
          className="missingUser"
          style={{
            width: "30vw",
            margin: "0 auto"
          }}
        >
          <PanelContainer
            title={`No pudimos encontrar a ${name}`}
            subtitle="¿Deseas agregarlo?"
          >
            <FontIcon
              iconName="fa-exclamation-circle"
              style={{
                color: "orange",
                fontSize: "5rem",
                margin: "20px"
              }}
            />
            <GridItem xs={12}>
              <FaButton
                aria-label="Registrar"
                onClick={() => {
                  this.setState({ openSearch: false });
                  optionalFn(this.props.onAddClick)();
                }}
                type="submit"
                size="14px"
                icon="plus"
                className={"submit"}
                background="linear-gradient(60deg, #007777, #007733)"
                style={{
                  color: "white",
                  textShadow: "1px 1px 1px black"
                }}
              >
                {" "}
                Registrar
              </FaButton>
            </GridItem>
          </PanelContainer>
        </div>
      );
    }
    return searchData.map((item, key) => (
      <GridItem key={key} xs={12} sm={12} md={4}>
        <UserCard
          pic={item.photo}
          fullName={item.fullname}
          type={item.type}
          okIcon={<Add />}
          others={{
            Acceso:
              item.hasAccess === 0 ? (
                <Badge type="warning">Por confirmar</Badge>
              ) : (
                <Badge type="go">Permitido</Badge>
              )
          }}
          onOk={() => {
            this.props.onInteraction(item.id);
            this.setState({ openSearch: false });
          }}
        />
      </GridItem>
    ));
  }
  render() {
    const { openSearch } = this.state;

    return (
      <React.Fragment>
        <GridItem xs={12}>
          <ActionInput
            id="searchUser"
            value={this.props.value}
            onClick={this.handleSearch}
            title="Buscar o Registrar nuevo visitante..."
            icon={<Search />}
          />
        </GridItem>
        <CustomModal
          open={openSearch}
          styles={{ width: "80vw" }}
          onClose={() => {
            this.setState({ openSearch: false });
          }}
        >
          <GridContainer>{this.printSearch(this.searchData)}</GridContainer>
        </CustomModal>
      </React.Fragment>
    );
  }
}
