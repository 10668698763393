import React, { Component } from "react";
import QrReader from "react-qr-reader";
import "./qr.scss";
import { optionalFn } from "../../core/helpers";
export class QrScan extends Component {
  state = {
    result: "No result"
  };
  handleScan = data => {
    if (data) {
      this.setState({
        result: data
      });
      optionalFn(this.props.onScan)(data);
    }
  };
  handleError = err => {
    console.error(err);
  };
  render() {
    return (
      <div className="qrContainer">
        <QrReader
          delay={1000}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    );
  }
}
