import React, { Component } from "react";
import { GenericSelector } from "../../components/CustomInput/GenericSelector";
import { AuthFetch } from "../../utils/AuthFetch";
import { Helpers, optionalFn } from "../../core/helpers";
import { FontIcon } from "./../../components/Icons/FontIcon";
import { types } from "./DoorsContainer";
export class DoorSelector extends Component {
  state = { options: [] };
  mapOptions(options) {
    const helper = new Helpers();
    options = helper.orderBy(options, "alias", "asc");
    return options.map(item => (
      <option key={item.id} value={item.id}>
        <span>{item.alias}</span>
        <FontIcon
          style={{ margin: "0px 5px" }}
          iconName={`fa-sign-${item.type ? "in" : "out"}-alt`}
        />
        ({types[item.type]})
      </option>
    ));
  }
  async loadOptions(type) {
    const fetch = new AuthFetch("api/doors");
    const doors = await fetch.get();
    this.setState({ options: doors.data });
  }
  componentDidMount() {
    this.loadOptions(this.props.type);
  }
  getData(id) {
    const helpers = new Helpers();
    const options = this.state.options;
    if (options.length === 0) {
      return false;
    }
    let data = helpers.searchByKey(options, "id", id);
    if (typeof this.props.getDoor === "function" && data.length > 0) {
      this.props.getDoor(data);
    }
    optionalFn(this.props.onChange)(id, data);
    return data;
  }
  render = () => {
    const { name, title, value, onChange, ...rest } = this.props;
    const options = this.state.options;
    return (
      <GenericSelector
        title={title}
        value={value}
        onChange={ev => {
          this.getData(ev.value);
        }}
        {...rest}
        name={name || "selector"}
      >
        {this.mapOptions(options)}
      </GenericSelector>
    );
  };
}
