import React, { Component } from "react";
import { AjaxForm } from "../../components/Containers/AjaxForm";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { SimpleInput } from "../../components/CustomInput/SimpleInput";
import { Translator } from "../../utils/Translator";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.jsx";
import { GenericSelector } from "../../components/CustomInput/GenericSelector";
export class DoorRegister extends Component {
  state = {
    alias: null,
    url: null,
    cmd: null,
    seconds: null,
    type: true
  };
  render = () => {
    const { alias, seconds, type } = this.state;
    const { onSubmit, id } = this.props;
    let load = false;
    let doorID = "";
    if (id != null) {
      load = true;
      doorID = id;
    }
    return (
      <AjaxForm
        autoload={load}
        path={`api/doors/${doorID}`}
        method={load ? "put" : "post"}
        submitted={onSubmit}
        willSubmit={inputs => {
          return inputs;
        }}
        getter={data => {
          this.setState(data.data);
        }}
      >
        <PanelContainer title="Evento" subtitle="Registrar un nuevo evento">
          <GridItem xs={12}>
            <SimpleInput
              required
              title="Nombre del evento"
              name="alias"
              value={alias}
            />
          </GridItem>
          <GridItem xs={12}>
            <SimpleInput
              required
              title="Tiempo Limite"
              name="seconds"
              value={seconds}
            />
          </GridItem>
          <GridItem xs={12}>
            <GenericSelector
              title="Tipo de Puerta"
              name="type"
              value={type}
              onChange={ev => {
                this.setState({ type: ev.value });
              }}
            >
              <option value={0}>Salida</option>
              <option value={1}>Entrada</option>
              <option value={2}>Peatonal</option>
            </GenericSelector>
          </GridItem>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={"submit"}
          >
            {new Translator("Save").get()}
          </Button>
        </PanelContainer>
      </AjaxForm>
    );
  };
}
